import { createProducts } from '/js/shared/element.js';
import { cookieadder } from '/js/shared/cookie.js';
import { navbaradder } from '/js/shared/navbar.js';
import { footeradder } from '/js/shared/footer.js';

$(document).ready(function () {
    navbaradder();
    footeradder();
    cookieadder();
    
    $('.banner-slider').slick({slidesToShow:calculateSlidesBanner(),slidesToScroll:calculateSlidesBanner(),autoplay:true,autoplaySpeed:2000,swipeToSlide:true});function calculateSlidesBanner(){var sliderWidth=$('.banner-slider').width();var slideWidth=300;var numslid=Math.round(sliderWidth/slideWidth);if(numslid>5){numslid=5;}
return numslid;}

    $(window).resize(function(){$('.slider').slick('slickSetOption','slidesToShow',calculateSlidesToShow());$('.banner-slider').slick('slickSetOption','slidesToShow',calculateSlidesBanner());});jQuery.ajax({type:"POST",url:'/php/index.php',dataType:'json',data:{functionname:'GR'},success:function(data){if(data.length>0){$("#error-message").hide();rabfillproducts(data);}
else{$("#error-message").show();}},error:function(){$("#error-message").show();}});jQuery.ajax({type:"POST",url:'/php/index.php',dataType:'json',data:{functionname:'GP'},success:function(data){if(data.length>0){$("#error-message").hide();popfillproducts(data);}
else{$("#error-message").show();}},error:function(){$("#error-message").show();}});function rabfillproducts(data){const output=$("#rab-store-products");const fragment=document.createDocumentFragment();output.html("");for(let i=0;i<data.length;i++){let product=createProducts(data[i].Product_Id,data[i].Product_Title,data[i].Product_Pic,data[i].Product_Desc,data[i].Product_Category,data[i].Product_Link,data[i].Product_Date,data[i].Product_LastPrice,data[i].Product_Price);fragment.appendChild(product);}
output.append(fragment);rabreinitializeSlider();}
function popfillproducts(data){const output=$("#pop-store-products");output.html("");for(let i=0;i<data.length;i++){let product=createProducts(data[i].Product_Id,data[i].Product_Title,data[i].Product_Pic,data[i].Product_Desc,data[i].Product_Category,data[i].Product_Link,data[i].Product_Date,data[i].Product_LastPrice,data[i].Product_Price);output.append(product);}
popreinitializeSlider();}



});


function calculateSlidesToShow(){var sliderWidth=$('.slider').width();var slideWidth=200;var numslid=Math.round(sliderWidth/slideWidth);if(numslid>5){numslid=5;}
return numslid;}
function rabreinitializeSlider(){$('.rabproductslider-nav').slick({slidesToShow:calculateSlidesToShow(),slidesToScroll:calculateSlidesToShow(),autoplay:true,autoplaySpeed:2000,swipeToSlide:true});}
function popreinitializeSlider(){$('.popproductslider-nav').slick({slidesToShow:calculateSlidesToShow(),slidesToScroll:calculateSlidesToShow(),autoplay:true,autoplaySpeed:2000,swipeToSlide:true});}