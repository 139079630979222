
export function navbaradder(){
    const nav = document.querySelector('.nav-navbar')
    fetch('/shared/navbar.html')
    .then(navres=>navres.text())
    .then(navdata=>{
        nav.innerHTML=navdata
    
        const navLinks = document.querySelectorAll('.nav-link');
        const currentUrl = window.location.pathname;
        if (currentUrl !== "/") {
            navLinks.forEach(link => {
                if(link.getAttribute('href').includes(currentUrl)) {
                    link.classList.add('active');
                }
    
            });
        }
        
    })
}


