// Importér billedet, så Parcel inkluderer det i bundlen
import placeholderImage from '/assets/shared/billede.webp';

export function createProducts(ProductID, Title, Pic, Desc, Category, Link, Date, LastPrice, Price) {

    let productdiv = document.createElement("div");
    productdiv.className = "column store-product " + Category;
    productdiv.dataset.date = Date;
    productdiv.dataset.price = Price;
    productdiv.id = ProductID;


    let productlink = document.createElement("a");
    productlink.className = "link";
    productlink.target = "_blank";
    productlink.rel = "noreferrer noopener";
    productlink.href = Link;

    let carddiv = document.createElement("div");
    carddiv.className = "card";

    let imagediv = document.createElement("div");
    imagediv.className = "divimage";

    let productimage = document.createElement("img");
    productimage.className = "productimg";
    productimage.onerror = function() { this.src=placeholderImage; };
    productimage.src = Pic;
    productimage.alt = Desc;

    imagediv.appendChild(productimage);

    carddiv.appendChild(imagediv);

    let producttitle = document.createElement("h4");
    producttitle.className = "producttitle";
    // Tjek længden af titlen og forkort den, hvis det er nødvendigt
    if (Title.length > 50) {
        producttitle.innerHTML = Title.substring(0, 50) + "...";
    } else {
        producttitle.innerHTML = Title;
    }

    carddiv.appendChild(producttitle);


    let divprice = document.createElement("div");
    divprice.className = "flex-grow-1 divprice";

    let innerdivprice = document.createElement("div");
    innerdivprice.className = "container";


    let lastprice = document.createElement("p");
    lastprice.className = "price";

    if (LastPrice != 0 && LastPrice != Price) {
        let lastpriceS = document.createElement("s");
        lastpriceS.innerHTML = LastPrice.toLocaleString("da-DK", { style: "currency", currency: "DKK" });

        lastprice.appendChild(lastpriceS);

        innerdivprice.appendChild(lastprice);
    }

    let price = document.createElement("p");
    price.className = "price aktprice";
    price.innerHTML = Price.toLocaleString("da-DK", { style: "currency", currency: "DKK" });

    innerdivprice.appendChild(price);

    divprice.appendChild(innerdivprice);

    carddiv.appendChild(divprice);


    let divbutton = document.createElement("div");

    let button = document.createElement("div");
    button.className = "card-btn";
    button.innerHTML = "Se mere";

    divbutton.appendChild(button);

    carddiv.appendChild(divbutton);

    productlink.appendChild(carddiv);

    productdiv.appendChild(productlink);

    return productdiv;

}